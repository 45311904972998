import React from 'react'

import { Container, Row, Col } from 'react-bootstrap';

import ImageCarousel from '../components/ImageCarousel';
import InfoPane from '../components/InfoPane';

import "../styles/main.scss";

function index() {
  return (
    <div className="index-page">
      <Container fluid>
        <Row>
          <Col 
            xs={{ span: 12, order: 'first'}}
            md={{ span: 6, order: 'last' }}
          >    
             <InfoPane />
          </Col>
          <Col 
            xs={{ span: 12, order: 'last'}}
            md={{ span: 6, order: 'first' }}
          >
            <ImageCarousel />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default index
