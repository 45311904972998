import React from 'react'

import { Container} from 'react-bootstrap';

import { StaticImage } from 'gatsby-plugin-image'

function Socials() {
  return (
    <Container fluid className="social-container">
      <a href="https://www.facebook.com/threelittleclouds" target="_blank" rel="noreferrer" className="social-button">
        <StaticImage className="social-image" src="../assets/facebook.png" />
      </a>

      <a href="https://www.instagram.com/threelittleclouds_/" target="_blank" rel="noreferrer" className="social-button">
        <StaticImage className="social-image" src="../assets/instagram.png" />
      </a>
    
      <a href="https://www.youtube.com/channel/UCO1FXvgymsuu6KGGijTEYEg" target="_blank" rel="noreferrer" className="social-button">
        <StaticImage className="social-image" src="../assets/youtube.png" />
      </a>

    </Container>
  )
}

export default Socials
