import React from 'react'
import FadeIn from 'react-fade-in';
import { StaticImage } from "gatsby-plugin-image"


function ImageCarousel() {
  return (
    <div className="carousel-container">
      <FadeIn delay={70}>
        <StaticImage alt="img" src='../assets/tlc1.jpg' placeholder="blurred"></StaticImage>
        <StaticImage alt="img" src='../assets/tlc2.jpg' placeholder="blurred"></StaticImage>
        <StaticImage alt="img" src='../assets/tlc3.jpg' placeholder="blurred"></StaticImage>
        <StaticImage alt="img" src='../assets/tlc4.jpg' placeholder="blurred"></StaticImage>
        <StaticImage alt="img" src='../assets/tlc5.jpg' placeholder="blurred"></StaticImage>
        <StaticImage alt="img" src='../assets/tlc6.jpg' placeholder="blurred"></StaticImage>
        <StaticImage alt="img" src='../assets/tlc7.jpg' placeholder="blurred"></StaticImage>
      </FadeIn>

      
    </div>
  )
}

export default ImageCarousel
