import React from 'react'

import { Container, Row, Col } from 'react-bootstrap';
import FadeIn from 'react-fade-in';

import { StaticImage } from 'gatsby-plugin-image';

import Socials from "./Socials";

function InfoPane() {

  const onBookerClick = (e) => {
    window.location = "mailto:maarten@agentsafterall.nl";
  }

  const onManageClick = (e) => {
    window.location = "mailto:micheletcaroline@hotmail.nl";
  }

  return (
      <Container className="info-pane-container">
          <StaticImage className="logo" src="../assets/logo-2.png" placeholder="blurred" alt="logo" />
        <FadeIn delay={120}>
          <div className="header">
            <h3>Get in touch with the funk.</h3>
            <span>For bookings please contact our booker Maarten. For inquiries connect with Caroline, our manager.</span>
          </div>
          <div className="outer-container">
          
            <Row className="info-row yellow-hover" onClick={onManageClick}>
              <Col className="info-col yellow-hover left" xs={12} md={6}>
                <span>Management:</span>
              </Col>
              <Col className="info-col yellow-hover right" xs={12} md={6}>
                <span>info@threelittleclouds.com</span>
              </Col>
            </Row>
            <Row className="info-row" onClick={onBookerClick}>
              <Col className="info-col yellow-hover left" xs={12} lg={6}>
                <span>Booker:</span>
              </Col>
              <Col className="info-col right" xs={12} lg={6}>
                <span>maarten@agentsafterall.nl</span>
              </Col>
            </Row>

          </div>

          <Socials  className="socials" />
        </FadeIn>
      </Container>
  )
}

export default InfoPane
